import { Dispatch } from "react"

import { SlotStatusEnum } from "../types/CalendarTypes"
// Any hard coded filter or sort options
export const INDUSTRIES = ["Tours", "Activities", "Accommodations", "Rentals", "Other"]

export const DATE_TIME_FORMAT = [
  {
    label: "Year-month-day",
    options: [
      { label: "22/12/14", value: "y/m/d" },
      { label: "2022-12-14", value: "Y-m-d" },
      { label: "2022, Dec 14, Wed", value: "Y, M d, D" },
    ],
  },
  {
    label: "Day-month-year",
    options: [
      { label: "14/12/22", value: "d/m/y" },
      { label: "14-12-2022", value: "d-m-Y" },
      { label: "Wed, 14 Dec, 2022", value: "D, d M, Y" },
    ],
  },
  {
    label: "Month-day-year",
    options: [
      { label: "12/14/22", value: "m/d/y" },
      { label: "12-14-2022", value: "m-d-y" },
      { label: "Wed, Dec 14, 2022", value: "D, M d, Y" },
      { label: "Dec 14, 2022", value: "M j, Y" },
    ],
  },
]
export const DATE_TIME_FORMAT_DEFAULT_VALUE = {
  label: "2022-12-14",
  value: "Y-m-d",
}

export const WEEKDAYS = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
]
export const WEEKDAYS_DEFAULT_VALUE = WEEKDAYS[0]

export const TIME_FORMAT_OPTIONS = [
  { label: "12h", value: "12h" },
  { label: "24h", value: "24h" },
]

export const QUANTITY_INDICATOR_OPTIONS = [
  { label: "Dropdown", value: "dropdown", showLabel: "Dropdown" },
  {
    label: "Increment/Decrement Button",
    value: "increment_decrement_button",
    showLabel: "Button",
  },
]

export const QUANTITY_INDICATOR_OPTION = QUANTITY_INDICATOR_OPTIONS[1]

export const TIME_FORMAT_DEFAULT_OPTION = TIME_FORMAT_OPTIONS[0]

export const ORDER_NUMBER_FORMAT_OPTIONS = [
  {
    label: "Sequential",
    value: "sequential",
  },
  {
    label: "Numeric",
    value: "numeric",
  },
  {
    label: "Alphanumeric",
    value: "alphanumeric",
  },
]
export const ORDER_NUMBER_FORMAT_DEFAULT_OPTION = ORDER_NUMBER_FORMAT_OPTIONS[0]

export const SHOW_ORDER_NUMBER_PREFIX_ON = ["Alphanumeric", "Sequential"]

export const PHP_TO_MOMENT_REPLACEMENTS: Record<string, string> = {
  d: "DD",
  D: "ddd",
  j: "D",
  l: "dddd",
  N: "E",
  S: "o",
  w: "e",
  z: "DDD",
  W: "W",
  F: "MMMM",
  m: "MM",
  M: "MMM",
  n: "M",
  t: "", // no equivalent
  L: "", // no equivalent
  o: "YYYY",
  Y: "YYYY",
  y: "YY",
  a: "a",
  A: "A",
  B: "", // no equivalent
  g: "h",
  G: "H",
  h: "hh",
  H: "HH",
  i: "mm",
  s: "ss",
  u: "SSS",
  e: "zz", // deprecated since Moment.js 1.6.0
  I: "", // no equivalent
  O: "", // no equivalent
  P: "", // no equivalent
  T: "", // no equivalent
  Z: "", // no equivalent
  c: "", // no equivalent
  r: "", // no equivalent
  U: "X",
}

export const AVAILABILITY_DETAILS_ITEMS = [
  {
    label: "Maximum number of Redemption",
    name: "is_max_redemption_time",
    input: "max_redemption_time",
    placeholder: "Quantity",
  },
  {
    label: "Minimum Order Price",
    name: "is_min_order_price",
    input: "min_order_price",
    placeholder: "Enter Minimum Order Price",
  },
  {
    label: "Maximum Order Price",
    name: "is_max_order_price",
    input: "max_order_price",
    placeholder: "Enter Maximum Order Price",
  },
]

export const APPLIES_OPTIONS = [
  {
    label: "Total Order",
    id: "total_order",
    value: "total_order",
  },
  {
    label: "Product Quantity",
    value: "product_quantity",
    id: "product_quantity",
  },
]

export const PROMO_CODE_TYPES = [
  {
    label: "Percentage",
    id: "code_type_percentage",
    value: "percent",
  },
  {
    label: "Fixed",
    id: "code_type_fix",
    value: "fixed",
  },
]

export const SAMPLE_PRODUCT_ITEMS = [
  {
    label: "AC Room",
    selected: false,
    id: "04e1ce2a-b9f4-40d7-9dd5-feb18cc232a1",
  },
  {
    label: "Party Hall",
    selected: false,
    id: "04e1ce2a-b9f4-40d7-9dd5-feb18cc232a2",
  },
  {
    label: "Boating",
    selected: false,
    id: "04e1ce2a-b9f4-40d7-9dd5-feb18cc232a3",
  },
  {
    label: "Club",
    selected: false,
    id: "04e1ce2a-b9f4-40d7-9dd5-feb18cc232a4",
  },
]

export enum PROMOCODE_STATUS {
  SCHEDULED = "scheduled",
  COMPLETED = "completed",
  EXPIRED = "expired",
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export const PROMOCODE_EDITABLE_STATUS_OPTIONS = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "In-active" },
]

export const industries = ["Tours", "Activities", "Accommodations", "Rentals", "Other"]
export const DateTimeFormat = [
  {
    label: "Year-month-day",
    options: [
      { label: "22/12/14", value: "y/m/d" },
      { label: "2022-12-14", value: "Y-m-d" },
      { label: "2022, Dec 14, Wed", value: "Y, M d, D" },
    ],
  },
  {
    label: "Day-month-year",
    options: [
      { label: "14/12/22", value: "d/m/y" },
      { label: "14-12-2022", value: "d-m-Y" },
      { label: "Wed, 14 Dec, 2022", value: "D, d M, Y" },
    ],
  },
  {
    label: "Month-day-year",
    options: [
      { label: "12/14/22", value: "m/d/y" },
      { label: "12-14-2022", value: "m-d-y" },
      { label: "Wed, Dec 14, 2022", value: "D, M d, Y" },
    ],
  },
]
export const DateTimeFormatDefaultValue = {
  label: "2022-12-14",
  value: "Y-m-d",
}

export interface AvailabilityDetailsItemsType {
  label: string
  name: string
  input: string
  placeholder: string
}
export const AvailabilityDetailsItems = [
  {
    label: "Maximum number of Redemption",
    name: "is_max_redemption_time",
    input: "max_redemption_time",
    placeholder: "Quantity",
  },
  {
    label: "Minimum Order Price",
    name: "is_min_order_price",
    input: "min_order_price",
    placeholder: "Enter Minimum Order Price",
  },
  {
    label: "Maximum Order Price",
    name: "is_max_order_price",
    input: "max_order_price",
    placeholder: "Enter Maximum Order Price",
  },
]

export const appliesOptions = [
  {
    label: "Total Order",
    id: "total_order",
    value: "total_order",
  },
  {
    label: "Product Quantity",
    value: "product_quantity",
    id: "product_quantity",
  },
]

export const promoCodeTypes = [
  {
    label: "Percentage",
    id: "code_type_percentage",
    value: "percent",
  },
  {
    label: "Fixed",
    id: "code_type_fix",
    value: "fixed",
  },
]

export const promoCodeStatus = {
  SCHEDULED: "scheduled",
  COMPLETED: "completed",
  EXPIRED: "expired",
  ACTIVE: "active",
  INACTIVE: "inactive",
}

export enum ProductInternalTypeEnum {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export const PromoCodeEditableStatusOptions = [
  { value: ProductInternalTypeEnum.ACTIVE, label: "Active" },
  { value: ProductInternalTypeEnum.INACTIVE, label: "In-active" },
]

/* TAX AND FEES OPTIONS */
export const TAX_AND_FEES_CATEGORY = [
  {
    label: "Tax",
    value: 1,
  },
  {
    label: "Fee",
    value: 2,
  },
]
export const TAX_AND_FEES_TYPES = [
  {
    label: "Percentage",
    value: 1,
  },
  {
    label: "Fixed per order item",
    value: 2,
  },
  {
    label: "Fixed per quantity",
    value: 3,
  },
  {
    label: "Fixed per duration",
    value: 4,
  },
]

export const ASK_PER_ORDER = "ask_per_order"
export const ASK_PER_PARTICIPANT = "ask_per_participant"
export const ASK_PER_BOOKING = "ask_per_booking"
export const ASK_PER_ITEM = "ask_per_item"

export const PRODUCT_STATUS = {
  ENABLED: "Active",
  DISABLED: "Disabled",
}

// VOUCHER
export const VOUCHER_TYPE = [
  {
    label: "Manual",
    value: "manual",
    id: "manual",
  },
  {
    label: "Auto-Generate",
    value: "automatic",
    id: "automatic",
  },
]

export const APPLICABLE_DATE_TYPE = [
  {
    label: "Booking",
    value: "booking",
  },
  {
    label: "Order",
    value: "order",
  },
]

export const VoucherAvailabilityDetailsItems = [
  {
    label: "minimum Amount",
    name: "is_min_order_price",
    input: "min_order_price",
    placeholder: "Enter Minimum Amount",
    type: "number",
  },
]
export interface VoucherAvailabilityDetailsItemsType {
  label: string
  name: string
  input: string
  type: string
  placeholder: string
}

export const VoucherDateFormat = "Y-m-d H:i:s"

export enum ProductInternalTypeEnum {
  BOOKING = "bookable",
  MERCHANDISE = "merchandise",
  EXTRA = "extra",
  GIFT_CARD = "gift_card",
  RENTALS = "rentals",
  MEMBERSHIP = "memberships",
}

export const ProductClosureStatusEnum = {
  Pending: "Pending",
  Active: "Active",
  Disabled: "Disabled",
  Archived: "Archived",
}

export const CustomerStatusEnum = {
  ACTIVE: "ACTIVE",
  Inactive: "Inactive",
}

export const SLOT_STATUS_SELECT_OPTIONS = [
  {
    label: "Open",
    value: SlotStatusEnum.OPEN,
  },
  {
    label: "Closed",
    value: SlotStatusEnum.CLOSED,
  },
]

export const ACTION = {
  add: "Add",
  edit: "Edit",
  create: "Create",
  update: "Update",
  delete: "Delete",
  archive: "Archived",
  view: "View",
}

export const domainPlaceholder = ".smartrezbooking.com"
export const widgetsDomainName = "SmartRez Booking"
export interface WrapperTypes {
  currentStep: string
  setShow?: Dispatch<React.SetStateAction<boolean>>
  renderTabsItem: () => React.ReactNode
  wrapperClass?: string
}

export const TEMPLATES_STATUS = {
  ACTIVE: "active",
  DRAFT: "draft",
}
export enum ProductInternalTypesEnum {
  ACTIVE = "Active",
  INACTIVE = "Disabled",
}

export const ProductEditableStatusOptions = [
  { value: ProductInternalTypesEnum.ACTIVE, label: "Active" },
  { value: ProductInternalTypesEnum.INACTIVE, label: "In-active" },
]

export const TemplateStatusOptions = [
  {
    value: TEMPLATES_STATUS.ACTIVE,
    label: "Active",
  },
  {
    value: TEMPLATES_STATUS.DRAFT,
    label: "Draft",
  },
]

export enum SignedWaiverTypesEnum {
  Approved = "approved",
  Pending = "pending",
  Declined = "declined",
}

export enum SignedWaiverLevelTypesEnum {
  Booking = "booking",
  Participant = "participant",
}

export const SignedWaiverOptions = [
  {
    value: SignedWaiverTypesEnum.Approved,
    label: "Approved",
  },
  {
    value: SignedWaiverTypesEnum.Pending,
    label: "Pending",
  },
  {
    value: SignedWaiverTypesEnum.Declined,
    label: "Declined",
  },
]

export const TicketTypeOptions = [
  // To DO:: add these option in to the future
  // {
  //   label:'Fixed',
  //   value:'fixed'
  // },
  // {
  //   label:'Per Person',
  //   value:'per_person'
  // },
  // {
  //   label:'Per Unit',
  //   value:'per_unit'
  // },
  {
    label: "Per Minute",
    value: "per_minute",
    isSingleDayOption: true,
  },
  {
    label: "Per Hour",
    value: "per_hour",
    isSingleDayOption: true,
  },
  {
    label: "Per Day",
    value: "per_day",
    isSingleDayOption: false,
  },
  {
    label: "Per Week",
    value: "per_week",
    isSingleDayOption: false,
  },
  {
    label: "Per Month",
    value: "per_month",
    isSingleDayOption: false,
  },
  {
    label: "Per Year",
    value: "per_year",
    isSingleDayOption: false,
  },
]

export enum SignedWaiverStatusEnum {
  Signed = "signed",
  Unsigned = "unsigned",
}
export const getDayName = (dayNumber: number) => {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  const index = Math.min(Math.max(0, dayNumber), 6)
  return daysOfWeek[index]
}
