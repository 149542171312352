import { AxiosRequestConfig } from "axios"

import { privateFetch, publicFetch } from "../libs/axios"
import {
  AddOrEditCustomersModalType,
  CustomerGoogleSigninResponseType,
  CustomerLoginForgotPasswordRequestType,
  CustomerLoginForgotPasswordResponseType,
  CustomerLoginResetPasswordRequestType,
  CustomerLoginResetPasswordResponseType,
  GetAllCustomerBookingsResponseType,
  GetAllCustomerOrdersResponseType,
  GetCustomerMembershipsResponseType,
  SendOtpRequestType,
  SendOtpResponseType,
  SigninCustomerWithPasswordRequestType,
  SignupCustomerRequestType,
  SignupCustomerResponseType,
  UpdateCustomerMembershipStatusRequestType,
  UpdateCustomerMembershipStatusResponseType,
  VerifyOtpRequestType,
} from "../types/CustomerTypes"
import {
  GetSavedCardsOfCustomerResponseType,
  SaveCardInStripeRequestType,
  SaveCardInStripeResponseType,
} from "../types/CustomerTypes"
import { jsonToQueryString } from "../utils/Common"

export const getAllCustomers = (filter = {}) =>
  privateFetch().get(`/api/v1/customers/${jsonToQueryString(filter)}`)

export const getCustomerById = (id = "") => privateFetch().get(`/api/v1/customers/${id}`)

export const addCustomer = (body: AddOrEditCustomersModalType) =>
  privateFetch().post<AddOrEditCustomersModalType>("/api/v1/customers", body)

export const updateCustomer = (
  id: string,
  body: AddOrEditCustomersModalType,
  isIntegratedPage: boolean
) => {
  if (isIntegratedPage) {
    return publicFetch().put(`/api/v1/public/customers/${id}`, body)
  }
  return privateFetch().put(`/api/v1/customers/${id}`, body)
}

export const deleteCustomer = (id: string) => privateFetch().delete(`/api/v1/customers/${id}`)

export const getAllCustomerBookings = (id: string, filter = {}) =>
  privateFetch().get<GetAllCustomerBookingsResponseType>(
    `/api/v1/customer/${id}/bookings${jsonToQueryString(filter)}`
  )

export const getAllCustomerOrders = (id: string, filter = {}) =>
  privateFetch().get<GetAllCustomerOrdersResponseType>(
    `/api/v1/customer/${id}/orders${jsonToQueryString(filter)}`
  )

export const getAllCustomerMemberships = async (
  customerId: string,
  filter = {},
  isIntegratedPage: boolean = false
) => {
  if (isIntegratedPage) {
    return await publicFetch().get<GetCustomerMembershipsResponseType>(
      `/api/v1/public/customer/${customerId}/memberships${jsonToQueryString(filter)}`
    )
  }
  return await privateFetch().get<GetCustomerMembershipsResponseType>(
    `/api/v1/customer/${customerId}/memberships${jsonToQueryString(filter)}`
  )
}

// CUSTOMER_LOGIN SERVICES

export const signupCustomer = (body: SignupCustomerRequestType) =>
  privateFetch().post<SignupCustomerResponseType>("/api/v1/customer/signup", body)

export const signinCustomerWithPassword = (body: SigninCustomerWithPasswordRequestType) =>
  privateFetch().post<SignupCustomerResponseType>("/api/v1/customer/login", body)

export const sendOtp = (body: SendOtpRequestType, isIntegratedPage?: boolean) => {
  if (isIntegratedPage) {
    return publicFetch().post<SendOtpResponseType>("/api/v1/public/send-otp", body)
  }
  return privateFetch().post<SendOtpResponseType>("/api/v1/send-otp", body)
}

export const verifyOtp = (body: VerifyOtpRequestType, isIntegratedPage?: boolean) => {
  if (isIntegratedPage) {
    return publicFetch().post<SignupCustomerResponseType>("/api/v1/public/verify-otp", body)
  }
  return privateFetch().post<SignupCustomerResponseType>("/api/v1/verify-otp", body)
}

export const customerSignout = () => privateFetch().post("/api/v1/customer/logout")

export const customerGoogleSignin = () =>
  publicFetch().post<CustomerGoogleSigninResponseType>("/api/v1/customer/auth/google")

export const customerGoogleSigninCallback = (
  callbackParams: string,
  config: AxiosRequestConfig = {}
) =>
  publicFetch().get<SignupCustomerResponseType>(
    `/api/v1/customer/auth/google/callback${callbackParams}`,
    config
  )

export const customerLoginForgotPassword = (body: CustomerLoginForgotPasswordRequestType) =>
  publicFetch().post<CustomerLoginForgotPasswordResponseType>(
    "/api/v1/customer/password/forgot",
    body
  )

export const customerLoginResetPassword = (body: CustomerLoginResetPasswordRequestType) =>
  publicFetch().post<CustomerLoginResetPasswordResponseType>(
    "/api/v1/customer/password/reset",
    body
  )

export const getAuthCustomer = async () =>
  await privateFetch().post<SignupCustomerResponseType["customer"]>(
    "/api/v1/customer/authenticated",
    {
      headers: {
        withCredentials: false,
      },
    }
  )

export const updateCustomerV2 = (id: string, body: FormData, headers?: AxiosRequestConfig) =>
  privateFetch().post(`/api/v1/customers/${id}`, body, headers)

export const updateCustomerMembershipStatus = (
  membershipId: string,
  body: UpdateCustomerMembershipStatusRequestType
) =>
  privateFetch().post<UpdateCustomerMembershipStatusResponseType>(
    `/api/v1/customer-membership/${membershipId}`,
    body
  )

export const saveCardInStripe = async (customerId: string, body: SaveCardInStripeRequestType) =>
  await privateFetch().post<SaveCardInStripeResponseType>(
    `/api/v1/customers/${customerId}/save-card`,
    body
  )

export const getSavedCardsOfCustomer = async (customerId: string) =>
  await privateFetch().get<GetSavedCardsOfCustomerResponseType>(
    `/api/v1/customers/${customerId}/cards`
  )

export const setSavedCardAsDefault = async (customerId: string, savedCardId: string) =>
  await privateFetch().post<SaveCardInStripeResponseType>(
    `/api/v1/customers/${customerId}/cards/${savedCardId}/assign-default`
  )
