const ENV = {
  API_URL: process.env.NEXT_PUBLIC_API_URL,
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN
}

const setENV = (env) => {
  if (env.API_URL) {
    ENV.API_URL = env.API_URL
  }

  if (env.SENTRY_DSN) {
    ENV.SENTRY_DSN = env.SENTRY_DSN
  }
}

module.exports = {
  ENV,
  setENV
}
