import { Button, Modal } from "flowbite-react"
import React, { createContext, ReactNode, useCallback, useRef, useState } from "react"

import { AttentionIcon } from "@assets/icons"

type ConfirmDialogContextType = (data: ConfirmDialogData) => Promise<boolean>

interface ConfirmDialogData {
  title?: string
  isOpen?: boolean
  icon?: ReactNode
  confirmBtnLabel?: string
  cancelBtnLabel?: string
  confirmButtonProps?: any
  cancelButtonProps?: any
}

export const ConfirmDialog = createContext<ConfirmDialogContextType>(() => Promise.resolve(false))

export default function ConfirmDialogProvider({ children }: { children: JSX.Element }) {
  const defaultValue: ConfirmDialogData = {
    isOpen: false,
    confirmBtnLabel: "Yes",
    cancelBtnLabel: "Cancel",
  }
  const [state, setState] = useState<ConfirmDialogData>(defaultValue)
  const fn = useRef<(choice: boolean) => void>()

  const confirm = useCallback(
    (data: ConfirmDialogData) =>
      new Promise<boolean>((resolve) => {
        setState({ ...defaultValue, ...data, isOpen: true })
        fn.current = (choice: boolean) => {
          resolve(choice)
          setState({ isOpen: false })
        }
      }),
    [setState, defaultValue]
  )

  return (
    <ConfirmDialog.Provider value={confirm}>
      {children}
      <Modal
        show={state.isOpen}
        size={"sm"}
        popup
        onClose={() => fn.current?.(false)}
        className={"z-[100]"}
      >
        <Modal.Header className={"!bg-neutral-50"} />
        <Modal.Body className={"py-6 px-4"}>
          <div className={"text-center"}>
            {state.icon ?? (
              <AttentionIcon
                className={"mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"}
              />
            )}
            <h3
              className={"paragraph14Regular mb-5 text-neutral-600 dark:text-gray-400"}
              dangerouslySetInnerHTML={{ __html: state.title ?? "" }}
            />
            <div className={"flex justify-center gap-4"}>
              <Button
                color={"error"}
                onClick={() => fn.current?.(true)}
                {...(state.confirmButtonProps ?? {})}
              >
                {state.confirmBtnLabel}
              </Button>
              <Button
                outline
                color={"gray"}
                onClick={() => fn.current?.(false)}
                {...(state.cancelButtonProps ?? {})}
              >
                {state.cancelBtnLabel}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </ConfirmDialog.Provider>
  )
}
