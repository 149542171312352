import { AxiosRequestConfig } from "axios"

import { privateFetch, publicFetch } from "../libs/axios"
import {
  BusinessProfileResponseType,
  UpdateBusinessProfileRequestType,
  UpdateScheduleSettingsRequestType,
  UpdateRegionalSettingRequestType,
} from "../types/TenantServiceTypes"
import { IDomain } from "../types/UserTypes"
import { getSelectedDomain } from "../utils/TenantHelper"

const domain = getSelectedDomain()

export const getTenant = async () => await privateFetch().get("/api/v1/tenant")

export const getDomainDetails = async (domainId?: string) =>
  await privateFetch().get<IDomain>(`/api/v1/domain/${domainId ?? domain}`)

export const getDomainBasicDetails = async (config: AxiosRequestConfig = {}) =>
  await publicFetch().get<IDomain>(`/api/v1/public/domain/details`, config)

export const updateBusinessProfile = async (body: UpdateBusinessProfileRequestType) => {
  const formData = new FormData()
  Object.keys(body as any).forEach((key) => {
    if ((body as any)[key] && !Array.isArray((body as any)[key])) {
      formData.append(key, (body as any)[key])
    }
    if (Array.isArray((body as any)[key])) {
      if ((body as any)[key]?.length) {
        ;(body as any)[key].forEach((item: any) => {
          if (item.file) {
            formData.append(`${key}`, item.file)
          }
        })
      } else {
        formData.append(key, "")
      }
    }
  })
  await privateFetch().post<BusinessProfileResponseType>(
    `/api/v1/domain/${domain}/profile`,
    formData
  )
}

export const updateRegionalSettings = async (body: UpdateRegionalSettingRequestType) =>
  await privateFetch().put<BusinessProfileResponseType>(`/api/v1/domain/${domain}/region`, body)

export const updateScheduleSettings = async (body: UpdateScheduleSettingsRequestType) =>
  await privateFetch().put<BusinessProfileResponseType>(`/api/v1/domain/${domain}/schedule`, body)

export const domainOrderField = async (id: string, body: any) =>
  await privateFetch().post(`api/v1/domain/${id}/store-fields`, body)

export const getDomainOrderFieldbyId = async (id: string) =>
  await privateFetch().get(`/api/v1/domain/${id}/fields`)

export const deleteDomainOrderField = async (domainId: string, fieldId: string) =>
  await privateFetch().delete(`/api/v1/domain/${domainId}/fields/${fieldId}`)

export const sortDomainOrderField = async (domainId: string, body: any) =>
  await privateFetch().post(`/api/v1/domain/${domainId}/sort-fields`, body)
