/* eslint-disable turbo/no-undeclared-env-vars */
import axios, { AxiosInstance } from "axios"
import axiosRetry from "axios-retry"

import { UserChannel } from "../../types/AuthServicesTypes"
import {
  getPlatform,
  getSelectedDomain,
  getSelectedTenant,
  getToken,
} from "../../utils/TenantHelper"
import Sentry from "../sentry"

const { ENV } = require("@mono/env")

const APIS_TO_RETRY = ["/api/v1/order/payment"]

let privateAxios: AxiosInstance
let publicAxios: AxiosInstance

export const initializePrivateAxios = () => {
  privateAxios = axios.create({
    baseURL: ENV.API_URL,
    headers: {
      Accept: "application/json",
      "X-Tenant": "",
      withCredentials: true,
    },
  })

  privateAxios.interceptors.request.use(async (config) => {
    const token = getToken()
    const tenant = getSelectedTenant()
    const subDomain = getSelectedDomain()
    const platform = getPlatform()

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    if (tenant) {
      config.headers["X-Tenant"] = tenant
    }
    if (subDomain) {
      config.headers["X-Subdomain"] = subDomain
    }
    if (platform) {
      config.headers["X-Platform"] = platform
    }
    if (!config.headers["X-Channel"]) {
      config.headers["X-Channel"] = UserChannel.OFFLINE
    }

    return config
  })

  privateAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (process.env.NODE_ENV !== "development") {
        Sentry.captureException(error)
      }
      return Promise.reject(error)
    }
  )

  axiosRetry(privateAxios, {
    retries: 3,
    retryCondition: (error) =>
      error.response?.config.url
        ? APIS_TO_RETRY.includes(error.response.config.url) && error.response.status !== 200
        : false,
  })
}

export const initializePublicAxios = () => {
  publicAxios = axios.create({
    baseURL: ENV.API_URL,
    headers: {
      Accept: "application/json",
      "X-Tenant": "",
      "X-Channel": "",
      "X-Subdomain": "",
    },
  })

  publicAxios.interceptors.request.use(async (config) => {
    const platform = getPlatform()
    let affiliateId = null
    let affiliateCode = null
    if (typeof window !== "undefined") {
      const subDomain = window.location.hostname.split(".", 1)[0]
      if (subDomain) {
        config.headers["X-Subdomain"] = subDomain
      }
      try {
        const url = new URL(window.location?.href)
        const { searchParams } = url
        // Google signin for customer login
        if (searchParams.get("state")) {
          const domainNameAsString = window.atob(searchParams.get("state") as string)
          if (domainNameAsString) {
            const domainName = JSON.parse(domainNameAsString)?.domain
            if (domainName) {
              config.headers["X-Subdomain"] = domainName
            }
          }
        }

        // Retrieve specific parameters
        affiliateId = searchParams.get("affiliateID")
        if (!affiliateId) {
          affiliateId = searchParams.get("attachable_id")
        }
        affiliateCode = searchParams.get("affiliate_code") || searchParams.get("affiliateID")
      } catch (e) {
        Sentry.captureException(e)
        console.error({ e })
      }
    }

    if (platform) {
      config.headers["X-Platform"] = platform
    }
    if (affiliateCode) {
      config.headers["X-AffiliateCode"] = affiliateCode
    }
    if (affiliateCode) {
      config.headers["X-Channel"] = UserChannel.AFFILIATE
    } else if (!config.headers["X-Channel"]) {
      config.headers["X-Channel"] = UserChannel.ONLINE
    }
    return config
  })

  publicAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (process.env.NODE_ENV !== "development") {
        Sentry.captureException(error)
      }
      return Promise.reject(error)
    }
  )
}

export const privateFetch = () => {
  if (!privateAxios) {
    initializePublicAxios()
  } else if (!(privateAxios?.defaults?.baseURL && ENV.API_URL)) {
    privateAxios.defaults.baseURL = ENV.API_URL
  }

  return privateAxios
}

export const publicFetch = () => {
  if (!publicAxios) {
    initializePublicAxios()
  } else if (!(publicAxios?.defaults?.baseURL && ENV.API_URL)) {
    publicAxios.defaults.baseURL = ENV.API_URL
  }

  return publicAxios
}

export const setAxiosToken = (token: string | undefined | null) => {
  if (token) {
    privateAxios.defaults.headers.Authorization = `Bearer ${token}`
  } else {
    delete privateAxios.defaults.headers.Authorization
  }
}

export const setAxiosTenant = (tenant: string | undefined | null) => {
  if (tenant) {
    privateAxios.defaults.headers["X-Tenant"] = tenant
  } else {
    delete privateAxios.defaults.headers["X-Tenant"]
  }
}

export const setAxiosSubDomain = (subDomain: string | undefined | null) => {
  if (subDomain) {
    privateAxios.defaults.headers["X-Subdomain"] = subDomain
  } else {
    delete privateAxios.defaults.headers["X-Subdomain"]
  }
}

export const setAxiosPlatform = (platform: string | undefined | null) => {
  if (platform) {
    privateAxios.defaults.headers["X-Platform"] = platform
  } else {
    delete privateAxios.defaults.headers["X-Platform"]
  }
}

export const setAxiosHeader = (key: string, value: string) => {
  privateAxios.defaults.headers.common[key] = value
}

export const removeAxiosHeader = async () => {
  delete privateAxios.defaults.headers["X-Tenant"]
  delete privateAxios.defaults.headers["X-Channel"]
  delete privateAxios.defaults.headers["X-Subdomain"]
  delete privateAxios.defaults.headers.Authorization
}

export const setAxiosBaseURL = (value: any) => {
  privateAxios.defaults.baseURL = value
  publicAxios.defaults.baseURL = value
}
