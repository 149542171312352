import { ITenant, IUser } from "./UserTypes"

export interface LoginResponseType {
  token: {
    access_token: string
    token_type: string
    expires_at: string | null
  }
  tenants: ITenant[]
}

export interface RegisterFormAttributes {
  first_name: string
  last_name: string
  email: string
  domain: string
  password: string
}

export interface RegisterServiceRequestType extends RegisterFormAttributes {
  "g-recaptcha-response": string
}

export interface RegisterResponseType {
  message: string
  tenant: ITenant
  token: {
    access_token: string
    token_type: string
    expires_at: string | null
  }
}

export interface RegisterUserRequestType {
  email: string
  first_name: string
  last_name: string
  password: string
  password_confirmation: string
}

export interface RegisterUserRequestHeaderType {
  "X-Domain": string
}

export interface RegisterUserResponseType {
  message: string
  user: IUser
  token: {
    access_token: string
    token_type: string
    expires_at: string | null
  }
}

export interface ForgotPasswordResponseType {
  message: string
}

export interface RegisterBusinessRequestType {
  primary_industry: string
  timezone_iso3: string
  phone: string
  dial_code: string
  state: string
  country: string
  currency_iso3: string
  website: string
  company: string
  location_name: string
  dontHaveAWebsite?: boolean
}

export enum UserPlatform {
  DESKTOPWEB = "desktop-website", // default
  MOBILEWEB = "mobile-website", // mobile web
  IOS = "mobile-ios", // ios admin
  ANDROID = "mobile-Android", // android admin
}

export enum UserChannel {
  OFFLINE = "offline",
  AFFILIATE = "affiliate",
  ONLINE = "online",
}
