import React, { createContext, useCallback, useState } from "react"

import MonoToast, { MonoToastProps } from "@components/elements/Toast"

type GlobalToastProps = MonoToastProps & {
  active?: boolean
}

const ToastContext = createContext<{
  showToast: (props: MonoToastProps) => void // showToast
  hideToast: () => void // hideToast
}>({ showToast: () => null, hideToast: () => null })

export default ToastContext

export const ToastContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [toast, setToast] = useState<GlobalToastProps>({
    heading: "",
    active: false,
  })

  const showToast = useCallback(
    ({ ...toastProps }: MonoToastProps) => {
      setToast({
        active: true,
        ...toastProps,
      })

      setTimeout(() => {
        setToast((prevState) => ({
          ...prevState,
          active: false,
        }))
      }, 5000)
    },
    [setToast]
  )

  const hideToast = useCallback(() => {
    setToast((prevState) => ({
      ...prevState,
      active: false,
    }))
  }, [setToast])

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {toast.active && (
        <div
          className={
            "fixed top-20 left-1/2 z-[100] flex -translate-x-1/2 translate-y-0 justify-center"
          }
        >
          <MonoToast {...toast} />
        </div>
      )}

      {children}
    </ToastContext.Provider>
  )
}
