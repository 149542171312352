// Add all the global utility functions such as formatting mobile numbers, date, cookie actions, local strorage & so on.
import Compressor from "compressorjs"
import cookie from "cookie"
import { extendMoment } from "moment-range"
import moment from "moment-timezone"
import { GetServerSidePropsContext } from "next"
import { NextRouter } from "next/router"

import { PHP_TO_MOMENT_REPLACEMENTS, ProductInternalTypeEnum } from "../constants/options"
import { ProductSteps } from "../constants/ProductOptions"
import routes from "../constants/routes"
import { setAxiosHeader } from "../libs/axios"
import Sentry from "../libs/sentry"
import { UserPlatform } from "../types/AuthServicesTypes"
import {
  CountryDetailType,
  CurrencyType,
  GlobalCategoryType,
  MerchandiseRoutes,
  ProductRoutes,
  SelectOptionType,
} from "../types/GlobalConfigTypes"
import { EditProductInfoRequestType, ProductStepsType } from "../types/ProductTypes"
import { UnitTypesLabelTypes } from "../types/UnitTypes"
import { OfferProductListType } from "../types/VoucherServiceTypes"

import { getDomainTimezone } from "./TenantHelper"

interface TimeInterval {
  label: string
  value: string
  product_availability_id: string
}

export const jsonToQueryString = (
  json: Record<string, string | number | boolean | undefined | any>
) =>
  `?${Object.keys(json)
    .filter((key) => Boolean(json[key]))
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(json[key] ?? "")}`
    })
    .join("&")}`

export const deepClone = (array: any) => {
  return JSON.parse(JSON.stringify(array))
}

export const getDomainName = (withPort?: boolean) => {
  if (typeof window !== "undefined") {
    let url: string | string[] = window.location.hostname
    url = url.replace(/(https?:\/\/)?(www.)?/i, "")
    url = url.split(".")

    url = url.slice(url.length - 2).join(".")
    if (url.indexOf("/") !== -1) {
      return url.split("/")[0]
    }

    const ports = ["80", "443"]

    if (withPort && !ports.includes(`${window.location.port}`)) {
      return `${url}:${window.location.port}`
    }

    return url
  }
}

export const getSubDomain = (url: string) => {
  const urlParts = url.split(".")
  if (urlParts.length >= 3 && urlParts[0] !== "www") {
    return urlParts[0]
  }
  return null
}

/**
 * This function returns a date-time in the specified timezone.
 * If no date is given, it will return the current date-time in the timezone.
 * If a format is specified, it will return the date-time as a string in that format.
 *
 * @param {string | Date | moment.Moment | null} date - The date-time to convert. If null, uses current date-time.
 * @param {string} format - The format string to use for the output. If not specified, a Moment object is returned.
 *
 * @returns {moment.Moment | string} The date-time in the specified timezone, either as a Moment object or as a string in the specified format.
 */
export const getDateInDomainTimezone = (
  date?: string | Date | moment.Moment | null,
  format?: string,
  tenantTimezone?: string
): moment.Moment | string => {
  const domainTimezone = getDomainTimezone() // get the selected timezone
  let mDate = date
  if (date && date instanceof Date) {
    const year = date?.getFullYear()
    const month = date?.getMonth()
    const day = date?.getDate()
    mDate = moment(mDate).set({ year, month, date: day })
  }
  const newDate = new Date()
  const year = newDate?.getFullYear()
  const month = newDate?.getMonth()
  const day = newDate?.getDate()
  // If no date is given, use the current date-time
  const timezoneLocal = date
    ? moment.tz(mDate, tenantTimezone ?? domainTimezone)
    : moment.tz(moment().set({ year, month, date: day }), tenantTimezone ?? domainTimezone)

  // If a format is specified, return the date-time as a string in that format
  if (format) {
    return timezoneLocal.format(phpToMoment(format))
  }

  // If no format is specified, return the date-time as a Moment object
  return timezoneLocal
}

export const toLocalDate = (date?: string | Date | moment.Moment) => {
  let mDate: any = date

  if (date && date instanceof Date) {
    const year = date?.getFullYear()
    const month = date?.getMonth()
    const day = date?.getDate()
    mDate = moment(mDate).set({ year, month, date: day })
  } else {
    mDate = moment(mDate)
  }

  // Extract year, month, and date from the source moment object
  const year = mDate?.year() as number
  const month = mDate?.month() as number // Months are 0-indexed in Moment.js
  const dt = mDate?.date() as number

  // Create a new Date object and set it using the extracted values
  const dateObject = new Date()
  dateObject.setFullYear(year, month, dt)

  return dateObject
}

export function getDaysDifferenceBasedonDates(startDate: any, endDate: any) {
  const start_date = startDate.toDate()
  const end_date = endDate.toDate()
  const timeDifference = end_date.getTime() - start_date.getTime()
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
  return daysDifference
}

export function isClosureBetweenRange(startDate: string, endDate: string, excludeDates: any) {
  // Convert start and end dates to Moment objects
  const momentStartDate = moment(startDate, "YYYY-MM-DD")
  const momentEndDate = moment(endDate, "YYYY-MM-DD")
  const isSelectionDateBetweenExcludedDates = excludeDates?.some((excludedDate: any) => {
    const currentDate = moment(excludedDate, "YYYY-MM-DD")
    return currentDate.isBetween(momentStartDate, momentEndDate, null, "[]")
  })
  return isSelectionDateBetweenExcludedDates
}

export const getBaseUrl = () => {
  if (typeof window !== "undefined") {
    const hostName = window.location.hostname
    // eslint-disable-next-line turbo/no-undeclared-env-vars
    if (process.env.NODE_ENV === "development") {
      return window.location.host.split(".").slice(1).join(".")
    }
    return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1)
  }
}

export const transformToDateTimeString = (date: string, time: string) => {
  console.log("time: ", time)
  const formattedDate = moment(date).format("YYYY-MM-DD")
  const formattedTime = moment(time).format("HH:mm:ss")
  return `${formattedDate} ${formattedTime}`
}

/**
 * This function converts a date format string from PHP to Moment.js.
 * It handles most of the format specifiers, but not all.
 * Some of the PHP format specifiers do not have an equivalent in Moment.js and are replaced with an empty string.
 *
 * @param {string} str - The PHP date format string to convert
 *
 * @returns {string} The converted Moment.js date format string
 */
export const phpToMoment = (str: string) => {
  return str
    .split("")
    .map((chr) => (chr in PHP_TO_MOMENT_REPLACEMENTS ? PHP_TO_MOMENT_REPLACEMENTS[chr] : chr))
    .join("")
}
/**
 * This function converts a date from a given timezone to UTC.
 * It has options to start the date at the beginning of the day and to format the output date string.
 *
 * @param {string} localDate - The local date to convert to UTC. This should be a string that Moment.js can parse.
 * @param {string} [format] - Optional. The format for the output date string. This should be in PHP date format, which will be converted to Moment.js format.
 * @param {boolean} [isStartOfDate=true] - Optional. Whether to start the date at the beginning of the day. Defaults to true.
 *
 * @returns {string} The date in UTC, as a string formatted according to the `format` parameter.
 */
export const toUtcTime = (localDate: string, format?: string, isStartOfDate: boolean = true) => {
  const timezone: string = getDomainTimezone() // get the selected timezone
  let date = moment.tz(localDate, timezone)

  if (isStartOfDate) {
    date = date.startOf("day")
  }

  const mDate = date.utc() // convert to UTC

  return format ? mDate.format(phpToMoment(format)) : mDate.format()
}

/**
 * This function converts a date without time from a given timezone to UTC that should be based on start of the day
 *
 * @param {string} localDate - The local date to convert to UTC. This should be a string that Moment.js can parse.
 * @param {string} [format] - Optional. The format for the output date string. This should be in PHP date format, which will be converted to Moment.js format.
 *
 * @returns {moment} The date in UTC, as a string formatted according to the `format` parameter.
 */
export const convertOnlyDateToUTC = (localDate: string, format?: string) => {
  const timezone = getDomainTimezone()

  // Create a new moment object in the selected timezone at the same calendar date
  const startAtTimezone = moment.tz(moment(localDate).format("YYYY-MM-DD"), timezone)

  // This should already be the start of the day, but we can enforce this to be sure
  const startOfDayPacific = startAtTimezone.startOf("day")

  // Convert this to UTC
  const startOfDayUTC = startOfDayPacific.clone().utc()

  return format ? startOfDayUTC.format(phpToMoment(format)) : startOfDayUTC
}
export const convertOnlyDateToUTCEndOfDay = (startAtTimezone: moment.Moment, format?: string) => {
  const timezone = getDomainTimezone()

  // Create a new moment object in the selected timezone at the same calendar date
  // const startAtTimezone = moment.tz(moment(localDate).format('YYYY-MM-DD'), timezone)

  // This should already be the start of the day, but we can enforce this to be sure
  const startOfDayPacific = startAtTimezone.endOf("day")

  // Convert this to UTC
  const endOfDayUTC = startOfDayPacific.clone().utc()
  return format ? endOfDayUTC.format(phpToMoment(format)) : endOfDayUTC
}

// for datepickers to set minimum date in js date format
export const getMinDateFromDomainTimezone = (localDate?: string | Date | moment.Moment | null) => {
  const domainTimezone: string = getDomainTimezone() // get the selected timezone
  const momentTimezone = localDate
    ? moment.tz(localDate, domainTimezone)
    : moment.tz(moment(), domainTimezone)

  // Extract the date and time components from the 'momentTimezone' instance
  const year = momentTimezone.year()
  const month = momentTimezone.month()
  const date = momentTimezone.date()
  const hours = momentTimezone.hours()
  const minutes = momentTimezone.minutes()
  const seconds = momentTimezone.seconds()
  const milliseconds = momentTimezone.milliseconds()

  // Create a new Date object with the extracted components
  return new Date(year, month, date, hours, minutes, seconds, milliseconds)
}

export const dateFormat = (localDate: string, format?: string) => {
  const domainTimezone: string = getDomainTimezone() // get the selected timezone
  let strTime = ""
  const date = moment.tz(localDate, domainTimezone)
  if (format) {
    strTime = date.format(phpToMoment(format)) // `${hours  }:${  minutes  } ${  ampm}`;
  } else {
    strTime = date.format()
  }
  return strTime
}

export const dateFormatWithoutTimezone = (
  date?: string | Date | moment.Moment | null,
  format?: string
) => {
  let mDate = date
  if (date && date instanceof Date) {
    const year = date?.getFullYear()
    const month = date?.getMonth()
    const day = date?.getDate()
    mDate = moment().set({ year, month, date: day })
  }
  return mDate
}

export const getLocalTimeFromDateAndTime = (date?: string, time?: string, format?: string) => {
  const domainTimezone: string = getDomainTimezone() // get the selected timezone
  let mDate: any = moment(`${date} ${time}:00` + " +00:00").tz(domainTimezone)

  if (format) {
    mDate = mDate.format(phpToMoment(format)) // `${hours  }:${  minutes  } ${  ampm}`;
  }
  return mDate
}

export const getLocalTimeFromDateTime = (date?: string, time?: string, format?: string) => {
  const domainTimezone: string = getDomainTimezone() // get the selected timezone
  // moment.tz.setDefault(domainTimezone);
  let mDate: any = moment.utc(`${date} ${time}`).toDate()

  if (format && mDate) {
    mDate = moment(mDate).format(phpToMoment(format)) // `${hours  }:${  minutes  } ${  ampm}`;
  }
  return mDate
}

export const getAmPmFromHISTime = (time: string) => {
  const timeAMPM = moment(time, "HH:mm:ss")

  const formattedTime = timeAMPM.format("h:mm A")
  return formattedTime
}

export const getHISFromAmPmTime = (time: string) => {
  const timeAMPM = moment(time, "h:mm A")

  const formattedTime = timeAMPM.format("HH:mm:ss")
  return formattedTime
}

export const getUTCTimeFromLocalDateTime = (date?: string, time?: string, format?: string) => {
  const domainTimezone: string = getDomainTimezone() // get the selected timezone
  // moment.tz.setDefault(domainTimezone);
  let mDate: any = moment(`${date} ${time}`).clone().utc()

  if (format && mDate) {
    mDate = mDate.format(phpToMoment(format)) // `${hours  }:${  minutes  } ${  ampm}`;
  }
  return mDate
}

/**
 * This function converts a UTC date-time to the local timezone.
 *
 * @param {string} date - A date string in UTC.
 * @param {string} time - A time string in 12-hour format (e.g., '02:30PM').
 *
 * @returns {Moment} The date-time object in the local timezone.
 */
export const utcTimeToLocalTime = (date: string, time: string): moment.Moment => {
  const domainTimezone = getDomainTimezone() // get the selected timezone

  const timeIn24hrFormat = moment(time, "hh:mmA").format("HH:mm:ss")

  return moment.tz(`${date} ${timeIn24hrFormat}`, domainTimezone)
}

/**
 * This function converts a UTC date to the local timezone.
 *
 * @param {string} date - A date string in UTC.
 * @param {string} [format] - Optional. The date format for the output. Default is ISO 8601.
 *
 * @returns {string} The date string in the local timezone and in the specified format.
 */
export const fromUtcToLocalTime = (date: string, format?: string): string => {
  const domainTimezone = getDomainTimezone() // get the selected timezone

  const clonedDate = moment(date).clone()
  const stillUtc = moment.utc(clonedDate).toDate()
  const localTime = moment(stillUtc).tz(domainTimezone)

  // Determine the output format
  const outputFormat = format ? phpToMoment(format) : undefined
  if (localTime && localTime.format) {
    return localTime?.format(outputFormat)
  }
  return localTime as unknown as string
}

/**
 * This function converts a UTC date to the local date ignoring timezone.
 * 2023-07-11 11:00:00 - it will ignore time
 * @param {string} date - A date string in UTC.
 * @param {string} [format] - Optional. The date format for the output. Default is ISO 8601.
 *
 * @returns {string} The date string in the local timezone and in the specified format.
 */
export const fromUtcToLocalDate = (date: string, format?: string): string => {
  const domainTimezone = getDomainTimezone() // get the selected timezone

  // Replace 'T' with a space if it exists in the date string
  // in case the date time is 2023-07-11T11:00:00
  const cleanedDate = date?.replace("T", " ") ?? ""

  // Split the date-time string into date and time parts
  const [datePart, timePart] = cleanedDate?.split(" ")

  // Create a new moment object in the local timezone at the same calendar date
  const localTime = moment.tz(datePart, domainTimezone)

  // Determine the output format
  const outputFormat = format ? phpToMoment(format) : undefined

  return localTime.format(outputFormat)
}

/**
 * This function gets the time from a given Date object in the desired format and timezone.
 *
 * @param {Date} date - The Date object to get the time from.
 * @param {string} format - The desired time format. Should be either '12h' or '24h'.
 *
 * @returns {string} The time in the desired format.
 */
export const getTime = (date: Date, format: string) => {
  const timezone: string = getDomainTimezone() // get the selected timezone
  let strTime = ""
  const momentDate = moment.tz(date, timezone)

  if (format === "12h") {
    strTime = momentDate.format(phpToMoment("h:i a"))
  } else if (format === "24h") {
    strTime = momentDate.format(phpToMoment("H:i"))
  }

  return strTime
}

export const getSelectedOptionsByVal = (
  options: {
    label: string
    value: string
  }[],
  val: string,
  defaultOptions?: {
    label: string
    value: string
    symbol?: string
  }[]
) => {
  const obj = options.find((opt) => opt.value === val)

  if (!obj && defaultOptions) {
    return defaultOptions
  }
  return obj
}

export const getCountryOptions = (countries: Record<string, CountryDetailType> = {}) => {
  const { USA } = countries
  const { CAN } = countries

  const countryOptions = Object.keys(countries)
    .map((index) => ({
      value: countries[index].code,
      label: countries[index].name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
    .filter((item) => item.value !== "US" && item.value !== "CA")

  if (USA) {
    countryOptions.unshift({
      value: USA.code,
      label: USA.name,
    })
  }
  if (CAN) {
    countryOptions.unshift({
      value: CAN.code,
      label: CAN.name,
    })
  }

  return countryOptions
}

export const findCurrencies = (code: string, currencies: CurrencyType[] = []) => {
  const currency = currencies.find((item) => item.code === code)
  let option = null
  if (currency) {
    option = {
      label: `${currency.name} (${currency.code})`,
      value: currency.code,
      symbol: currency.symbol,
    }
  }

  return option
}

export const getProductNameById = (id: string, products: OfferProductListType[] = []) => {
  const item = products.find((prod) => prod.id === id)
  return item && item.label
}

export const gotoProductNextStep = async (
  navigate: NextRouter,
  currentStep: number,
  id: string,
  qs?: string,
  steps?: ProductStepsType[],
  pType?: string
) => {
  try {
    const p_steps = steps || ProductSteps
    const slug = p_steps[currentStep + 1]?.value as keyof ProductRoutes as keyof MerchandiseRoutes
    switch (pType) {
      case ProductInternalTypeEnum.MERCHANDISE:
        return await navigate.push(`${routes.merchandise[slug].replace(":id", id)}${qs || ""}`)
      case ProductInternalTypeEnum.MEMBERSHIP:
        return await navigate.push(`${routes.membership[slug]?.replace(":id", id)}${qs || ""}`)
      default:
        return await navigate.push(`${routes.product[slug].replace(":id", id)}${qs || ""}`)
    }
  } catch (e: any) {
    Sentry.captureException(e)
    console.error({ e })
  }
}
export const gotoProductPreviousStep = async (
  navigate: NextRouter,
  currentStep: number,
  id: string,
  qs?: string,
  steps?: ProductStepsType[],
  pType?: string
) => {
  try {
    if (currentStep <= 0) {
      return
    }
    const P_steps = steps || ProductSteps
    const slug = P_steps[currentStep - 1]?.value as keyof ProductRoutes as keyof MerchandiseRoutes
    switch (pType) {
      case ProductInternalTypeEnum.MERCHANDISE:
        return await navigate.push(`${routes.merchandise[slug].replace(":id", id)}${qs || ""}`)
      case ProductInternalTypeEnum.MEMBERSHIP:
        return await navigate.push(`${routes.membership[slug]?.replace(":id", id)}${qs || ""}`)
      default:
        return await navigate.push(`${routes.product[slug].replace(":id", id)}${qs || ""}`)
    }
  } catch (e: any) {
    Sentry.captureException(e)
    console.error({ e })
  }
}

export function getUniqueArrayByKey(arr: any[], key: string): any[] {
  const uniqueValues = new Map()

  for (const item of arr) {
    const keyValue = item[key]
    if (!uniqueValues.has(keyValue)) {
      uniqueValues.set(keyValue, item)
    }
  }
  return Array.from(uniqueValues.values())
}

export function isObjectBlank(obj: { [key: string]: any }): boolean {
  if (Object.keys(obj).length === 0) {
    return true
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== "" && obj[key] !== undefined) {
      return false
    }
  }

  return true
}

export const parseProductInfo = ({
  id,
  name,
  code,
  description,
  visibility,
  status,
  type,
  categories,
  deposit,
  widget_description,
  merchandise,
}: EditProductInfoRequestType) => {
  const cat: GlobalCategoryType[] = categories as GlobalCategoryType[]
  return {
    id,
    name,
    code,
    description,
    visibility,
    status,
    type,
    deposit: {
      deposit_type: deposit?.type,
      deposit_amount: deposit?.amount,
    },
    product_type_id: type?.id,
    widget_description,
    categories: categories.map((category: any) => category.id),
    category_id: cat ? cat[0].id : "",
    merchandise,
  }
}

export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const getConfigAndIdFromServerContext = (context: GetServerSidePropsContext) => {
  const id = context.query.id as string

  const cookieHeader = context.req.headers.cookie
  const cookies = cookie.parse(cookieHeader || "")

  setAxiosHeader("token", cookies.token)
  setAxiosHeader("x-tenant", cookies["x-tenant"])
  setAxiosHeader("x-sub-domain", cookies["x-sub-domain"])

  const config = {
    headers: {
      Authorization: `Bearer ${cookies.token}`,
      "x-tenant": cookies["x-tenant"],
      "x-sub-domain": cookies["x-sub-domain"],
    },
  }
  console.log({
    config,
  })
  return {
    id,
    config,
    query: context.query,
    cookies,
  }
}

export const getOS = () => {
  const uA = navigator.userAgent
  if (/iPad|iPhone|iPod/.test(uA) || (uA.includes("Mac") && "ontouchend" in document)) return "iOS"

  let i
  const os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"]
  for (i = 0; i < os.length; i++) if (new RegExp(os[i], "i").test(uA)) return os[i]
}

export const getDevicePlatform = () => {
  const deviceDetector = (userAgent = "") => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        userAgent.substr(0, 4)
      )
    ) {
      return true
    }
    return false
  }
  return deviceDetector(navigator.userAgent || navigator.vendor || (window as any).opera)
    ? UserPlatform.MOBILEWEB
    : UserPlatform.DESKTOPWEB
}

export const isDayPast = (date: string, format?: string) => {
  const currentDate = getDateInDomainTimezone()
  return moment(date, format ?? "YYYY-MM-DD").isBefore(currentDate, "day")
}

export const calculatePaidLaterAmount = (
  totalAmount: number | undefined,
  depositAmount: number | undefined
) => {
  if (totalAmount && depositAmount) {
    const paidLaterAmount = totalAmount - depositAmount
    return paidLaterAmount
  }

  return "$0.00"
}

export const currencySymbolRegex = /^[^\d]+|[^a-zA-Z\d]+$/g

export const formatPhoneNumber = (countryCode?: string, phoneNumber?: string) => {
  // Remove any non-numeric characters from the phone number
  const numericPhoneNumber = phoneNumber?.replace(/\D/g, "")
  // Format the phone number in the fixed format
  return `${countryCode?.startsWith("+") ? countryCode : `+${countryCode ?? ""}`} (${numericPhoneNumber?.slice(0, 3)})-${numericPhoneNumber?.slice(3, 6)}-${numericPhoneNumber?.slice(6)}`
}

export const getDayName = (dayNumber: number) => {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  const index = Math.min(Math.max(0, dayNumber), 6)
  return daysOfWeek[index]
}

export const injectSubdomain = (url: string, subdomain: string) => {
  const parsedUrl = new URL(url)
  if (subdomain.length === 0) {
    return parsedUrl.toString()
  }
  parsedUrl.host = `${subdomain}.${parsedUrl.host}`
  return parsedUrl.toString()
}

export function getSubdomain() {
  const { host } = window.location
  const parts = host.split(".")
  if (parts.length > 2) {
    parts.pop()
    parts.pop()
    if (parts.length === 1 && parts[0] === "www") {
      return ""
    }
    return parts.join(".")
  }
  return ""
}

export const formatDate = (inputDate: string) => {
  const date = new Date(inputDate)

  const day = date.getDate().toString().padStart(2, "0")
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const year = date.getFullYear()

  const formattedDate = `${year}-${month}-${day}`

  return formattedDate
}

// utils/jsonReader.ts
export const readMetaJsonData = () => {
  // Check if the script tag with the provided ID has been added to the DOM
  const scriptTag = document.getElementById("domaindetails")
  if (scriptTag) {
    // Retrieve the JSON data attribute
    const jsonData = scriptTag.getAttribute("data-json")
    if (jsonData) {
      try {
        // Parse the JSON string to an object
        const parsedJson = JSON.parse(jsonData)
        return parsedJson
      } catch (error) {
        Sentry.captureException(error)
        console.error("Error parsing JSON:", error)
      }
    }
  }

  return {} // Return null if JSON data is not found
}

export const toDomainDateFormat = (mDate: moment.Moment | string, showTime?: boolean) => {
  const parseDate = moment(mDate)

  const domainDetails = readMetaJsonData()
  const date_format = domainDetails?.date_format ?? "D, d M, Y"
  const time_format = domainDetails?.time_format ?? "12h"
  let strTime = ""
  if (showTime) {
    if (time_format === "12h") {
      strTime = phpToMoment("h:i a")
    } else if (time_format === "24h") {
      strTime = phpToMoment("H:i")
    }
  }
  return parseDate.format(`${phpToMoment(date_format)} ${strTime}`)
}

const parseHexColor = (hex: string) => {
  hex = hex.replace(/^#/, "")

  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return { r, g, b }
}

const formatRGB = (color: { r: number; g: number; b: number }) =>
  `rgb(${color.r} ${color.g} ${color.b})`

const lightenColor = (color: { r: number; g: number; b: number }, factor: number) => {
  const newR = Math.min(255, color.r + (255 - color.r) * factor)
  const newG = Math.min(255, color.g + (255 - color.g) * factor)
  const newB = Math.min(255, color.b + (255 - color.b) * factor)

  return formatRGB({ r: newR, g: newG, b: newB })
}

const darkenColor = (color: { r: number; g: number; b: number }, factor: number) => {
  const newR = color.r * (1 - factor)
  const newG = color.g * (1 - factor)
  const newB = color.b * (1 - factor)

  return formatRGB({ r: newR, g: newG, b: newB })
}

const generateColorVariants = (baseColor: string) => {
  const baseColorObj = parseHexColor(baseColor)

  const variant1 = lightenColor(baseColorObj, 0.6)
  const variant2 = lightenColor(baseColorObj, 0.43)
  const variant3 = darkenColor(baseColorObj, 0.1)
  const variant4 = darkenColor(baseColorObj, 0.2)
  const variant5 = darkenColor(baseColorObj, 0.375)

  return {
    400: variant1,
    500: variant2,
    700: variant3,
    800: variant4,
    900: variant5,
  }
}

export const getRGBColor = (hex: string, type: string) => {
  if (!hex) {
    return
  }
  const color = generateColorVariants(hex)
  return `--color-${type}-400: ${color["400"]};
  --color-${type}-500: ${color["500"]};
  --color-${type}: ${hex};
  --color-${type}-700: ${color["700"]};
  --color-${type}-800: ${color["800"]};
  --color-${type}-900: ${color["900"]};
  --tw-ring-color: var(--color-primary);
  `
}

export const getCompressedImage = async (imageUrl: File) => {
  try {
    const compressedBlob = await new Promise((resolve, reject) => {
      new Compressor(imageUrl, {
        quality: 0.6, // Adjust the desired image quality (0.0 - 1.0)
        maxHeight: 2048, // Adjust the maximum height of the compressed image
        mimeType: "image/jpeg", // Specify the output image format
        success(result: any) {
          resolve(result)
        },
        error(error: any) {
          reject(error)
        },
      })
    })

    return compressedBlob
  } catch (error) {
    Sentry.captureException(error)
    console.log(error)
  }
}

export const getDayDifference = (startDate: any, endDate: any) => {
  const dayDifference = Math.abs(startDate.diff(endDate, "days"))

  return dayDifference
}

export const compressAndUploadImage = async (file: any) => {
  try {
    const compressedBlob = await new Promise((resolve, reject) => {
      // Use Compressor with the blob object
      new Compressor(file, {
        quality: 0.6, // Adjust the desired image quality (0.0 - 1.0)
        maxHeight: 2048, // Adjust the maximum height of the compressed image
        mimeType: "image/jpeg", // Specify the output image format
        success: async (compressedBlob) => {
          console.log("compressedBlob: ", compressedBlob)
          // Handle compressedBlob which contains the compressed image data
          // Now you can upload the compressed image to your server or wherever you need
          resolve(compressedBlob)
        },
        error: (error: any) => {
          console.log("Error compressing image:", error)
          reject(error)
        },
      })
    })

    return compressedBlob
  } catch (error) {
    Sentry.captureException(error)
    console.log("Error compressing image:", error)
    return error
  }
}

export const getDomainBasedDateAndTimeFormat = (
  date: string,
  isDateWithTime: boolean = false,
  showOnlyTime: boolean = false
) => {
  const domainDetails = readMetaJsonData()
  if (showOnlyTime) {
    if (domainDetails?.time_format === "12h") {
      return fromUtcToLocalTime(date, `g:i A`)
    }
    return fromUtcToLocalTime(date, `H:i`)
  }
  if (isDateWithTime) {
    if (domainDetails?.time_format === "12h") {
      return fromUtcToLocalTime(date, `${domainDetails?.date_format || "Y-m-d"} g:i A`)
    }
    return fromUtcToLocalTime(date, `${domainDetails?.date_format || "Y-m-d"} H:i`)
  }
  return fromUtcToLocalTime(date, domainDetails?.date_format ?? "Y-m-d")
}

export function sortAndUniqueTimeIntervals(intervals: TimeInterval[]): TimeInterval[] {
  const uniqueMap: { [key: string]: TimeInterval } = {}

  intervals.forEach((interval) => {
    uniqueMap[interval.label] = interval
  })

  const uniqueIntervals = Object.values(uniqueMap)

  return uniqueIntervals.sort((a, b) => {
    const startA = moment(a.label.split("-")[0], "hh:mm A")
    const startB = moment(b.label.split("-")[0], "hh:mm A")
    return startA.diff(startB)
  })
}

export const checkFirstAvalibilityDate = (dateString: string) => {
  const momentDate = moment.utc(dateString).local()
  if (moment().isAfter(momentDate)) {
    return null
  }
  return toLocalDate(momentDate)
}

export const mapDynamicTicketTypeLabels = (
  productUnitTypes: any[],
  unitTypesLabelData: UnitTypesLabelTypes
) => {
  const optionsArray: SelectOptionType[] = []

  if (productUnitTypes?.length && unitTypesLabelData) {
    productUnitTypes.forEach((data) => {
      const options: SelectOptionType[] = []

      if (data.is_multi_day === 1 && data.has_flexible_timings === 0) {
        options.push({ value: "multi_day", label: unitTypesLabelData?.multi_day || "Multi-Day" })
      } else if (data.is_multi_day === 1 && data.has_flexible_timings === 1) {
        options.push({
          value: "multi_day_flex",
          label: "Multi-Day - Flexible Timings",
        })
      }

      if (data.is_multi_day === 0 && data.has_flexible_timings === 0) {
        options.push({
          value: "single_day",
          label: unitTypesLabelData?.single_day_fixed || "Single Day - Fixed Time",
        })
      } else if (data.is_multi_day === 0 && data.has_flexible_timings === 1) {
        options.push({
          value: "single_day_flex",
          label: unitTypesLabelData?.single_day_flexi || "Single Day - Flexible Timings",
        })
      }
      options.forEach((option: any) => {
        if (!optionsArray.some((existingOption) => existingOption.value === option.value)) {
          optionsArray.push(option)
        }
      })
    })
  }
  return optionsArray
}
