import { Flowbite, useTheme } from "flowbite-react"
import { AppProps } from "next/app"
import Router from "next/router"
import NProgress from "nprogress"
import React from "react"
import "nprogress/nprogress.css"
import { Toaster } from "react-hot-toast"

import "regenerator-runtime/runtime" // REF https://github.com/nrwl/nx/issues/5063
import AuthProvider from "@contextProviders/AuthProvider"
import ConfirmDialogProvider from "@contextProviders/ConfirmDialogProvider"
import { ToastContextProvider } from "@contextProviders/ToastProvider/ToastContext"
import customTheme from "@theme/components"

import "@theme/custom.css"
import "@theme/globals.css"
import "@theme/typography.css"
import "@theme/site.css"
import "@assets/revolvapp/css/revolvapp-frame.min.css"
import "@assets/revolvapp/css/revolvapp.min.css"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import { initializePrivateAxios, initializePublicAxios } from "@mono/essentials/libs/axios"

const { setENV } = require("@mono/env")

setENV({ API_URL: process.env.NEXT_PUBLIC_API_URL, SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN })
initializePrivateAxios()
initializePublicAxios()

const queryClient = new QueryClient()

const App = ({ Component, pageProps }: AppProps) => {
  Router.events.on("routeChangeStart", () => {
    NProgress.start()
  })

  Router.events.on("routeChangeComplete", () => {
    NProgress.done()
  })

  Router.events.on("routeChangeError", () => {
    NProgress.done()
  })

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Flowbite
        theme={{
          theme: {
            ...useTheme().theme,
            ...customTheme,
          },
        }}
      >
        <ToastContextProvider>
          <AuthProvider>
            <ConfirmDialogProvider>
              <Component {...pageProps} />
            </ConfirmDialogProvider>
          </AuthProvider>
          <Toaster
            position={"top-center"}
            reverseOrder={false}
            gutter={8}
            containerClassName={""}
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
              },

              // Default options for specific types
              success: {
                duration: 3000,
              },
            }}
          />
        </ToastContextProvider>
      </Flowbite>
    </QueryClientProvider>
  )
}

export default App
