import { TipType } from "./CartTypes"
import { GetRoleResponseType } from "./StaffManagementServicesType"

export interface ITeam {
  id: string
  domain_id: string
  tenant_id: string
  domain: {
    id: string
    domain: string
  }
}

export interface IRoleInUsers {
  id: string
  team_id: string
  user_id: string
  roles: {
    id: string
    name: string
    pivot: { model_id: string; role_id: number; model_type: string }
  }[]
  user: {
    id: string
  } | null
  team: ITeam | null
}

export interface BusinessHoursType {
  day: string
  open_time: string
  close_time: string
}

interface TipOptions {
  amount: number
}

export interface IDomain {
  id: string
  tenant_id: string
  domain: string
  is_primary: boolean
  is_fallback: boolean
  location_name: string
  website: string | null
  primary_industry: string | null
  email: string | null
  phone: string | null
  dial_code: string | null
  company_description: string | null
  address_1: string | null
  address_2: string | null
  city: string | null
  state: string | null
  zip: string | null
  country: string | null
  timezone_iso3: string | null
  currency_iso3: string | null
  currency_symbol: string | null
  language_iso2: string | null
  order_number_format: string
  booking_number_prefix: string
  affiliate_invoice_prefix?: string | null
  order_number_prefix: string | null
  show_taxes_fees_breakdown: boolean
  internal_order_confirm_on_create: boolean
  date_format: string
  time_format: string
  created_at: string
  updated_at: string
  company: string | null
  week_starts_on: string
  business_hours: BusinessHoursType[]
  tip_status: string
  tip_type: TipType
  tip_options: TipOptions[]
  cancellation_reasons: string[] | null
  is_custom_tip_allowed: boolean
  logo?: string | null
  member_login_bg_image?: string | null
  quantity_indicator: string
  show_levy_breakdown_in_widget: boolean
  enable_per_participant_waiver: boolean
  cancel_past_bookings_on_order_cancellation: boolean
  enable_per_booking_documents: boolean
  enable_per_booking_waiver: boolean
  enable_per_participant_documents: boolean
  email_tenant_on_booking: boolean
  enable_dropdown_in_order_placement: boolean
  enable_tip_staff_per_staff: boolean
  enable_staff_assignments: boolean
  enable_dynamic_pricing: boolean
  allow_past_bookings: boolean
  ga_tracking: GaTracking
  enable_deposit_option: boolean
  enable_expense_discount: boolean
  enable_membership: boolean
  enable_customer_login: boolean
  tip_notes: string
  tip_status_in_widget: string
  tip_number: string
  invoice_description: string
}

export interface GaTracking {
  type: string
  id: string
}

export interface ITenant {
  id: string
  company: string | null
  phone: string | null
  domains: IDomain[]
}

export interface IUser {
  id: string
  first_name: string
  last_name: string
  name: string
  email: string
  profile_url: string | null
  created_at: string
  updated_at: string
  domainRoles: IRoleInUsers[]
  tenants: ITenant[]
  status: string
  phone_number: string
  dial_code: string
}

export interface PaginationLinkType {
  first: string
  last: string
  prev: string | null
  next: string | null
}

export interface MetaDataType {
  current_page: number
  from: number
  last_page: number
  links: {
    url: string | null
    label: string
    active: boolean
  }[]
  path: string
  per_page: number
  to: number
  total: number
}

export interface AuthUserType {
  data: IUser[]
  links: PaginationLinkType
  meta: MetaDataType
}

export enum UserPermissionsEnum {
  viewer = "viewer",
  contributor = "contributor",
  manager = "manager",
  notAllowed = "notAllowed",
}
export enum UserPermissionSectionsEnum {
  affiliate = "affiliate",
  business = "business",
  calendar = "calendar",
  customer = "customer",
  dashboard = "dashboard",
  inventory = "inventory",
  manifest = "manifest",
  order = "order",
  booking = "booking",
  payment = "payment",
  report = "report",
  template = "template",
  user = "user",
  waiver = "waiver",
  widget = "widget",
}
export interface AuthUserResponseType extends IUser {
  permissions: Record<UserPermissionSectionsEnum, UserPermissionsEnum>
  roles: GetRoleResponseType[]
}
