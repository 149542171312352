import { privateFetch } from "../libs/axios"
import {
  ForgotPasswordResponseType,
  LoginResponseType,
  RegisterBusinessRequestType,
  RegisterResponseType,
  RegisterServiceRequestType,
  RegisterUserRequestHeaderType,
  RegisterUserRequestType,
  RegisterUserResponseType,
} from "../types/AuthServicesTypes"
import { GlobalConfigType } from "../types/GlobalConfigTypes"
import { AuthUserResponseType, AuthUserType } from "../types/UserTypes"

export type ForgotPasswordFormAttributes = {
  email: string
}

export type LoginFormAttributes = {
  email: string
  password: string
}

export interface ResetPasswordFormAttributes {
  password: string
  password_confirmation: string
  token: string
  email: string
}

export const login = async (body: LoginFormAttributes) =>
  await privateFetch().post<LoginResponseType>("/api/v1/login", body)

export const register = async (body: RegisterServiceRequestType) =>
  await privateFetch().post<RegisterResponseType>("/api/v1/register", body)

export const registerUser = async (
  body: RegisterUserRequestType,
  headers: RegisterUserRequestHeaderType
) => await privateFetch().post<RegisterUserResponseType>("/api/v1/user", body, { headers } as any)

export const forgotPassword = async (body: ForgotPasswordFormAttributes) =>
  await privateFetch().post<ForgotPasswordResponseType>("/api/v1/password/forgot", body)

export const resetPassword = async (body: ResetPasswordFormAttributes) =>
  await privateFetch().post("/api/v1/password/reset", body)

export const registerBusiness = async (body: RegisterBusinessRequestType) =>
  await privateFetch().put("/api/v1/register/step-2", body)

export const csrfCookie = async () => await privateFetch().get("/sanctum/csrf-cookie")

// updateUserDetails: async (body) => await privateFetch().put('/api/v1/user', body),
// uploadProfileImage: async (body) => await privateFetch().post('/api/v1/upload-profile-image', body, {
//   headers: {
//       'Content-Type': 'multipart/form-data',
//   },
// }),

export const getAuthUser = async () =>
  await privateFetch().get<AuthUserResponseType>("/api/v1/user/authenticated", {
    headers: {
      withCredentials: false,
    },
  })

export const getConfig = async () =>
  await privateFetch().get<GlobalConfigType>("/api/v1/config", {
    headers: {
      withCredentials: false,
    },
  })

export const getUserList = async () => await privateFetch().get<AuthUserType>("/api/v1/user")

export const logout = async () => await privateFetch().post("/api/v1/logout")

export const impersonate = async (token: string) => {
  return await privateFetch().post("/api/v1/impersonate", { token })
}
