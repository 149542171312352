import Cookies from "js-cookie"

import { getDomainName } from "./Common"

export const setLocalStorage = ({ key, value }: { key: string; value: string }) => {
  if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
    Promise.resolve().then(() => {
      localStorage.setItem(key, value)
    })
  }
}

export const removeLocalStorage = ({ key }: { key: string }) => {
  if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
    localStorage?.removeItem(key)
  }
}

export const getLocalStorage = ({ key }: { key: string }) => {
  if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
    return localStorage?.getItem(key)
  }
  return ""
}

export const setCookie = ({
  key,
  value,
  params,
}: {
  key: string
  value: string
  params?: Cookies.CookieAttributes
}) => {
  if (key !== "x-domain-tz") {
    Cookies.set(key, value, {
      domain: getDomainName(),
      ...params,
    })
  }

  // if (key !== 'token') {
  setLocalStorage({ key, value })
  // }
}

export const removeCookie = ({
  key,
  params,
}: {
  key: string
  params?: Cookies.CookieAttributes
}) => {
  Cookies.remove(key, {
    domain: getDomainName(),
    ...params,
  })
  removeLocalStorage({ key })
}

export const getCookie = ({ key }: { key: string }) => {
  const cookieValue = Cookies.get(key)
  if (cookieValue && key !== "x-domain-tz" && cookieValue != undefined) {
    return cookieValue
  } else {
    const cookieValue = getLocalStorage({ key }) as string
    return cookieValue
  }
}
