import { getDevicePlatform } from "./Common"
import { getCookie, removeCookie, setCookie } from "./CookieHelper"

export const storeXTenant = (tenant: string) => {
  setCookie({ key: "x-tenant", value: tenant })
  return tenant
}
export const storeXDomain = (tenant: string) => {
  setCookie({ key: "x-sub-domain", value: tenant })
  return tenant
}

export const storeXDomainTimezone = (timezone: string) => {
  setCookie({ key: "x-domain-tz", value: timezone, params: { sameSite: "None", secure: true } })
  return timezone
}

export const storeXDomainCurrency = (currency: string) => {
  setCookie({ key: "x-domain-currency", value: currency })
  return currency
}

export const storeXReportsHideColums = (name: string, columns: string[]) => {
  setCookie({ key: name, value: JSON.stringify(columns) })
}

export const storeXPlatform = () => {
  setCookie({
    key: "x-platform",
    value: getDevicePlatform(),
    params: { sameSite: "None", secure: true },
  })
}

export const getToken = () => getCookie({ key: "token" })
export const getDomainTimezone = (): string => getCookie({ key: "x-domain-tz" }) as string
export const getDomainCurrency = (): string => getCookie({ key: "x-domain-currency" }) as string
export const getSelectedTenant = () => getCookie({ key: "x-tenant" })
export const removeSelectedTenant = () => removeCookie({ key: "x-tenant" })
export const getSelectedDomain = () => getCookie({ key: "x-sub-domain" })
export const getHiddenColumns = (name: string) => getCookie({ key: `${name}` })
export const removeSelectedDomain = () => removeCookie({ key: "x-sub-domain" })
export const removeDomainTimezone = () => removeCookie({ key: "x-domain-tz" })
export const removeDomainCurrency = () => removeCookie({ key: "x-domain-currency" })
export const getPlatform = () => getCookie({ key: "x-platform" })
